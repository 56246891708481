<template>
  <div class="passedSuccess-box u-text-center">
    <div class="passedSuccess-item">
      <img class="icon-right" src="../assets/images/icon_check_markMax.png" />
      <h3 class="passedSuccess-title">预审通过</h3>
      <span class="passedSuccess-span">
        您的贷款申请已在极融花通过预审<br />
        请及时下载APP完成额度领取
      </span>

      <div class="passedSuccess-item">
        <div class="u-flex">
          <img class="icon-logo" src="../assets/images/logo.png" />
          <div class="u-text-left">
            <div class="carLoanSuccess-tit">极融花</div>
            <div class="tips">为您授信<span>50,000元</span>贷款额度</div>
            <div>
              <van-tag color="rgba(53,99,250,0.1)" text-color="#3563FA">
                随借随还
              </van-tag>
              <van-tag color="rgba(53,99,250,0.1)" text-color="#3563FA">
                通过率高
              </van-tag>
              <van-tag color="rgba(53,99,250,0.1)" text-color="#3563FA">
                下款快
              </van-tag>
            </div>
          </div>
        </div>

        <!-- 借款参数展示 -->
        <div class="borr-parameters">
          <div class="countdown u-flex">
            <span>14.80%</span>
            <p class="tips-p">
              <van-count-down
                :time="4 * 24 * 60 * 60 * 1000"
                format="DD天HH:mm:ss后恢复"
              />
            </p>
          </div>
          <ul class="u-flex u-row-between borr-parameters-ul u-text-left">
            <li>
              <p>8.00%</p>
              <small>限时综合年化</small>
            </li>
            <li>
              <p>36</p>
              <small>最高可借期限 (月)</small>
            </li>
            <li>
              <p>
                <count-to
                  :start-val="0"
                  :decimals="2"
                  :end-val="50000"
                  :duration="2000"
                />
              </p>
              <small>最高借款金额 (元)</small>
            </li>
          </ul>
          <div class="originate-loan u-flex u-row-between">
            <p>
              已成功向
              <span>
                <count-to :start-val="0" :end-val="28680" :duration="2000" />
              </span>
              人发起贷款
            </p>
            <p>
              本次放款资金剩余
              <count-to :start-val="100" :end-val="10" :duration="2000" />
              %
            </p>
          </div>
          <div class="step u-flex-1">
            <div
              class="step-line pr"
              :class="stepLineMove ? 'step-line-move' : ''"
            />
          </div>
        </div>

        <!-- 下载APP完成取款 -->
        <div class="pr">
          <van-button class="btn" type="default" @click="handleDownload">
            下载APP完成取款
          </van-button>
          <small class="pa tips-p btn-tips">30天免息券*2已发放，下载使用</small>
        </div>
      </div>
    </div>

    <div class="passedSuccess-item" style="margin-bottom: 0">
      <div class="passedSuccess-item" style="padding-top: 0.3rem">
        <div class="u-flex passedSuccess-item-b">
          <img class="icon-logo-mini" src="../assets/images/logo.png" />
          <div class="u-text-left">
            <div class="carLoanSuccess-tit">极融花</div>
            <div class="tips">正规持牌机构，应你之急！</div>
          </div>
        </div>
        <ul class="advice-ul u-flex u-row-between">
          <li class="u-flex-col u-col-center">
            <img class="advice-img" src="../assets/images/icon_cp.png" />
            <span>持牌机构</span>
          </li>
          <li class="u-flex-col u-col-center">
            <img class="advice-img" src="../assets/images/icon_dk.png" />
            <span>贷款保障</span>
          </li>
          <li class="u-flex-col u-col-center">
            <img class="advice-img" src="../assets/images/icon_aq.png" />
            <span>安全便捷</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 底部风险提示 -->
    <div class="passedSuccess-item bottom u-text-center">
      综合年化6%-24%，最终结果以审批为准<br />
      贷款有风险，借款需谨慎<br />
      请根据个人能力合理贷款，理性消费<br />
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "Download",
  data() {
    return {
      stepLineMove: false, // 进度条的默认状态
      channel: this.$route.query.source, // 渠道标识
      brand: this.$route.query.brand, // 手机型号标识
      downAppUrl: undefined, // 下载地址
      showPop: false, // 分享蒙层状态
    };
  },
  mounted() {
    this.getDownloadUrl();

    setTimeout(() => {
      this.stepLineMove = true;
    }, 1000);
  },
  methods: {
    // 下载按钮点击
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.brand === "xiaomi") {
          window.location.href = "https://app.xiaomi.com/detail/1500555";
        } else {
          window.location.href = this.downAppUrl;
        }
      }
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
          this.handleDownload();
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.passedSuccess-box {
  height: 100vh;
  background: #f1f2f6;
  padding-bottom: 0.88rem;
  font-size: 0.24rem;

  > div.passedSuccess-item {
    background: #fff;
    padding: 0.3rem 0.4rem 0.36rem;
    margin-bottom: 0.24rem;
  }

  .icon-right {
    width: 1.14rem;
    height: 1.14rem;
    margin: 0 auto 0.36rem;
  }

  .passedSuccess-title {
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #111c31;
  }

  .passedSuccess-span {
    display: block;
    margin: 0.24rem 0 0.48rem;
    color: #868e9e;
  }

  .passedSuccess-item {
    padding: 0.4rem 0.3rem 0.3rem;
    background: #f6f8ff;
    border-radius: 0.4rem;

    .icon-logo {
      width: 1.32rem;
      height: 1.32rem;
      margin-right: 0.24rem;
    }

    .icon-logo-mini {
      width: 0.84rem;
      height: 0.84rem;
      margin-right: 0.24rem;
    }

    .carLoanSuccess-tit {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #111c31;
    }

    .tips {
      color: #868e9e;
      margin: 0.04rem 0 0.08rem;

      span {
        color: #f03d23;
      }
    }

    .van-tag {
      margin-right: 0.12rem;
      font-size: 0.22rem;
    }

    .borr-parameters {
      margin: 0.3rem 0 0.48rem;
      padding-top: 0.3rem;
      border-top: 0.02rem solid #e6e7ee;

      .countdown {
        span {
          text-decoration: line-through;
          margin-right: 0.04rem;
          color: #c3c2c6;
        }
      }

      .borr-parameters-ul {
        margin: 0.04rem 0 0.44rem;

        p {
          font-size: 0.4rem;
          font-family: AlibabaPuHuiTiR, AlibabaPuHuiTiR-Regular;
          color: #111c31;
          margin-bottom: 0.04rem;
        }

        small {
          color: #868e9e;
        }
      }

      .originate-loan {
        color: #868e9e;

        p:first-child {
          > span {
            color: #f03d23;
          }
        }

        p:last-child {
          background: #ffede9;
          font-size: 0.22rem;
          color: #eb503b;
          padding: 0 0.14rem;
          line-height: 0.4rem;
          border-radius: 0.3rem;
        }
      }

      .step {
        margin-top: 0.24rem;
        height: 0.18rem;
        background: #ffede9;
        border-radius: 0.12rem;
      }

      .step-line {
        min-width: 1%;
        width: 1%;
        height: 100%;
        border-radius: 0.6rem;
        animation: warning-animation 2s infinite linear;
        background: linear-gradient(
          -45deg,
          #ffede9 25%,
          #f74c0b 0,
          #f4270c 50%,
          #f4a587 0,
          #ffffff 75%,
          #f03016 0
        );
        background-size: 0.16rem 0.16rem;
      }

      .step-line-move {
        width: 90%;
        transition: width 2s linear;
      }

      .step-line:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        border-radius: 0.24rem;
        background-image: linear-gradient(
          to bottom,
          #fddca4,
          rgba(243, 243, 229, 60%) 15%,
          transparent 60%,
          #f3f9e8
        );
      }

      @keyframes warning-animation {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 1rem 0;
        }
      }
    }
  }

  .passedSuccess-item-b {
    background: #e7edfe;
    padding: 0.24rem;
    border-radius: 0.16rem;
  }

  .advice-ul {
    margin-top: 0.24rem;
    color: #111c31;
    padding: 0 5%;

    .advice-img {
      width: 0.84rem;
      height: 0.84rem;
      margin-bottom: 0.12rem;
    }
  }

  .tips-p {
    height: 0.35rem;
    background: #f03d23;
    border-radius: 2rem 2rem 2rem 0;
    padding: 0 0.16rem;
    color: #fff;
    line-height: 0.35rem;
    font-size: 0.22rem;

    .van-count-down {
      color: #fff;
      line-height: 0.35rem;
      font-size: 0.22rem;
    }
  }

  .btn {
    width: 100%;
    background: #3563fa;
    border-radius: 0.48rem;
    font-size: 0.32rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.84rem;
  }

  .btn-tips {
    left: 41%;
    bottom: 70%;
  }

  .bottom {
    padding-top: 0;
    margin: 0 auto;
    font-family: PingFangSC, PingFangSC-Regular;
    font-size: 0.22rem;
    color: rgba(134, 142, 158, 0.8);
    line-height: 0.36rem;
    white-space: pre-line;
  }
}
</style>
